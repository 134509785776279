<template>
  <div class="summaryPageWrap">
    <m-layout-bar>
      <div class="summaryWrap">
        <div class="titleWrap">
          <div class="gap"></div>
          <span>乐园介绍</span>
          <img :src="require('../assets/image/summary/title.png')" alt />
        </div>
        <div class="contentWrap" v-html="dataInfo.info"></div>

        <!-- 轮播 -->
        <div class="swiperWrap">
          <div class="ry-content swiper">
            <div class="swiper-container sc-swiper" id="scaleSwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(pic, index) in dataInfo.photos" :key="index">
                  <a href="javascript:;">
                    <img style="width:1002px; height: 400px" :src="baseUrl + pic" alt />
                    <!-- <img
                      style="width:1002px; height: 400px"
                      :src="require('../assets/image/summary/'+banner+'')"
                      alt
                    />-->
                  </a>
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar";

import Swiper from "swiper";
import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      // banners: ["pic.png", "pic1.png", "pic2.png"],
      dataInfo: {
        info: "",
        photos: new Array()
      }
    };
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  created() {},
  mounted() {
    this.getData(config.summaryUrl);
  },
  methods: {
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let resdata = res.data.data[0];
            this.dataInfo.info = resdata.info;
            this.dataInfo.photos = resdata.photos.split(",");

            this.$nextTick(function() {
              this.galleryScale(); //必须放在$nextTick中，因为在初始化swiper前，需要取好数据，再渲染数据
            });
            // console.log("res.data.data:", res.data.data[0], this.dataInfo);
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    },
    galleryScale() {
      this.gallery = new Swiper("#scaleSwiper", {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        autoplay: {
          disableOnInteraction: false //操作后不会停止播放，如果不设置，左右按钮操作之后，轮播图会停止
        },
        loop: true,
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: -180,
        observer: true
      });
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" >
.summaryPageWrap {
  .summaryWrap {
    .contentWrap {
      p {
        line-height: 1.8;
        padding: 5px 1px;
      }
    }
  }
}
</style>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.swiper {
  position: relative;
}

.sc-swiper {
  width: 1400px;
  cursor: pointer;
  padding: 20px 0 60px;
}

.sc-swiper .swiper-slide {
  width: 1002px;
  height: 400px;
  background-color: #fff;
  opacity: 0.7;
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -webkit-transition: transform 1s;
  -moz-transition: transform 1s;
}

.sc-swiper .swiper-slide-active {
  transform: scale(1);
  opacity: 1;
  z-index: 10;
}

.sc-swiper .swiper-slide.swiper-slide-prev {
  transform: scale(0.7);
}

.sc-swiper .swiper-slide.swiper-slide-next {
  transform: scale(0.7);
}

.sc-item {
  display: block;
  width: 1002px;
  overflow: hidden;
}

// .sc-swiper .swiper-slide-active .sc-item {
//   box-shadow: 0 6px 18px rgba(55, 139, 236, 0.29);
// }

.sc-item img {
  width: 1002px;
  height: 400px;
}

.swiperWrap {
  width: 1400px;
  height: 339px;
  margin: 0 auto 204px;
  // border: 1px solid red;
}

.swiper-button-prev,
.swiper-button-next {
  width: 80px;
  height: 80px;
  top: 0;
  margin-top: 0;
  // background: $main-blue;
  border-radius: 2px;
  // display: block;
  align-items: flex-end;
  top: auto;
  bottom: 0;
}

.swiper-button-prev {
  left: 118px;
}
.swiper-button-next {
  right: 118px;
}

.swiper-button-prev:after {
  position: absolute;
  // transform: translateY(100%);
  color: $main-white;
  background-image: url(~@/assets/image/summary/arrow_left.png) !important;
  background-size: 80px 80px;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  font-family: none;
  content: "";
  // background-color: red;
}

.swiper-button-next:after {
  color: $main-white;
  background-image: url(~@/assets/image/summary/arrow_right.png) !important;
  background-size: 80px 80px;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  font-family: none;
  content: "";
  // background-color: yellow;
}

.swiper-container {
  --swiper-theme-color: #ff6600;
  --swiper-navigation-color: #fff; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 40px; /* 设置按钮大小 */
  // border: 1px solid green;
  padding-bottom: 150px;
}
// ---------------------------------------------------------------------
.summaryWrap {
  padding-top: 35px;
  padding-bottom: 150px;
  background: $bg-gray;
  border: 1px solid transparent;
  .titleWrap {
    width: 710px;
    height: 112px;
    position: relative;
    margin: 0 auto;
    text-align: center;
    // background: #d2eef5;

    > img {
      position: absolute;
      top: 0;
      left: 2px;
      z-index: 0;
    }
    > span {
      position: absolute;
      bottom: 5px;
      width: 160px;
      height: 35px;
      font-size: 33px;
      font-weight: bold;
      color: $font-grayDarkD;
      // line-height: 5.5;
      background: $bg-gray;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 1;
    }
    .gap {
      position: absolute;
      bottom: 15px;
      width: 524px;
      border-top: 4px solid $gap-green;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 0;
    }
  }
  .contentWrap {
    width: 1400px;
    min-height: 435px;
    margin: 20px auto;
    font-size: 13px;
    color: $font-grayDarkD;
    border: 1px solid transparent;
    p {
      text-indent: 2em;
      line-height: 1.8;
      padding: 5px 1px;
    }
  }
  .picShowWrap {
    width: 640px;
    height: 339px;

    margin: 0 auto 70px auto;
    box-shadow: 0px 1px 39px 8px $gap-green;
    border: 1px solid $gap-green;
    border-radius: 10px;
  }
}
</style>
